import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CallService } from '../../core/call-service/browser/call.service';
import { LogService } from '../../core/log/log.service';
import {
    saveExternalConsentsByTagAndNumUrl,
} from '../../core/router/routes-provider';
import {
    ExternalConsent,
    ExternalConsentResponse,
    
} from './external-consent.model';

@Injectable({ providedIn: 'root' })
export class ExternalConsentService {
    constructor(
        private callService: CallService,
        private logService: LogService,
        private httpClient: HttpClient
    ) {}
    saveExternalConsentsByTagAndNum(
        consent: ExternalConsent,
        
    ) {
        const urlToCall = saveExternalConsentsByTagAndNumUrl();
		this.logService.info('Calling ', urlToCall);
        return this.callService.CallApi<ExternalConsentResponse>('POST', urlToCall, consent);
    }
}
