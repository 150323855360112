import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { ExternalConsentService } from "./external-consent.service";
import { LogService } from "src/app/core/log/log.service";
import { Store } from "@ngrx/store";
import { NotificationService } from "src/app/core/notifications/notification.service";
import { TranslateService } from "@ngx-translate/core";
import { ExternalConsentActions } from "./external-consent.actions";
import { onlyWhenAuthenticated, onlyWhenOnline } from "src/app/shared/util/operators";
import { catchError, concatMap, map, switchMap, tap } from "rxjs/operators";
import { EnvironmentService } from "src/environments/environment.service";
import { ErrorMessage } from "../error/error-message.model";
import { selectUserId } from "../user/user.selectors";
import { concatLatestFrom } from "@ngrx/operators";
import { manageError } from "../error/error.util";
import { selectAuthLoginState } from "src/app/core/auth/auth.selectors";
import { ExternalConsent, ExternalConsentResponse } from "./external-consent.model";
import { of } from "rxjs";
import { showSnackbar } from "src/app/core/notifications/notification.actions";

@Injectable({ providedIn: 'root' })
export class ExternalConsentsEffects {
    constructor(
        private actions$: Actions,
        private externalConsentsService: ExternalConsentService,
        private logService: LogService,
        private store: Store,
        private envService: EnvironmentService,
        private notificationService: NotificationService,
    private ts: TranslateService
    ) { }

    saveExternalConsentsByTagandNum$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ExternalConsentActions.try),
            tap((action) => this.logService.info('Effect: saveExternalConsentsByTagAndNum', action)),
            onlyWhenOnline(this.store),
            onlyWhenAuthenticated(this.store),
            switchMap(({ externalConsent }) =>
                this.externalConsentsService.saveExternalConsentsByTagAndNum(externalConsent).pipe(
                    tap((response:ExternalConsentResponse) =>
                        this.logService.infoDebug('Effect: saveExternalConsentsByTagAndNum data received --> ', response)
                    ),
                    map((response:ExternalConsentResponse) => ExternalConsentActions.success({ consent:response })),
                    catchError((error: ErrorMessage) => of(ExternalConsentActions.failure({ error })))
                )
            )
        )
    );

    loadExternalConsentByTagAndNumSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ExternalConsentActions.success),
            tap(({consent}) =>
                this.logService.info('Effect: loadExternalConsentByTagAndNumsuccess ', consent)
            ),
            map(({consent}) =>  showSnackbar({
                                        payload: this.notificationService.getOkNotification(
                                            0,
                                            this.ts.instant('dottnet.consent.thirdpartyconsent.accepted') + " " + consent.customerDescription,
                                        )
                                    })
            
    )
    ));

    loadExternalConsentByTagAndNumFailure$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ExternalConsentActions.failure),
            concatLatestFrom(() => this.store.select(selectAuthLoginState)),
            tap((action) =>
                this.logService.info('Effect: loadExternalConsentByTagAndNumFailure ', action)
            ),
            tap(([{error}]) => this.store.dispatch(showSnackbar({
                payload: this.notificationService.getErrorNotification(
                    0,
                    this.ts.instant('dottnet.consent.thirdpartyconsent.failed') +" " + error.messageDN,
                )
            }))),
            map(([{ error }, authLoginState]) => manageError(error, authLoginState))
        )
    );
}

