import { createActionGroup, emptyProps, props } from "@ngrx/store";
import {
  ExternalConsent,
  ExternalConsentResponse,
} from "./external-consent.model";
import { ErrorMessage } from "../error/error-message.model";

export const ExternalConsentActions = createActionGroup({
  source: "External Consent",
  events: {
    // defining an event without payload using the `emptyProps` function
    try: props<{externalConsent: ExternalConsent}>(),

    // defining an event with payload using the `props` function
    success: props<{ consent: ExternalConsentResponse }>(),

    // defining an event with payload using the props factory
    failure: props<{ error: ErrorMessage }>(),
  },
});
