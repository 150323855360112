<!-- Change class of the following div to change app-theme -->

<div class="flex-col flex-justify-space-between light-theme flex-align-center">

  <mat-sidenav-container>
    <mat-sidenav #sidenav mode="over" fixedInViewport="true">
      <dottnet-sidenav [sidenav]="sidenav" [user]="user$ | async" [authLoginState]="authLoginState$ | async"
        [searchText]="searchText" [topic]="topic$ | async" />
    </mat-sidenav>
    <mat-sidenav-content id="sidenav-content" class="flex-col flex-justify-space-between desktop-content">

      <dottnet-toolbar [sidenav]="sidenav" [user]="user$ | async" [authLoginState]="authLoginState$ | async"
        [searchText]="searchText" [topic]="topic$ | async" />

      <dottnet-consent-management />

      <div class="flex-row flex-justify-center outlet-container">
        <router-outlet #o="outlet" />
      </div>
      <dottnet-footer [authLoginState$]="authLoginState$" [facebookLink$]="fbLink" [twitterLink$]="twitterLink"
        [linkedinLink$]="linkedinLink" [instagramLink$]="instagramLink" />
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>