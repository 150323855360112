import { createFeature, createReducer, on } from '@ngrx/store';
import { ExternalConsent, ExternalConsentResponse } from './external-consent.model';
import { ExternalConsentActions } from './external-consent.actions';

interface State {
  consents: ExternalConsentResponse;
  loading: boolean;
}

const initialState: State = {
  consents: <ExternalConsentResponse>{},
  loading: false,
};

export const consentsFeature = createFeature({
  name: 'consents',
  reducer: createReducer(
    initialState,
    on(ExternalConsentActions.try, (state) => ({
      ...state,
      loading: true,
    })),
    on(ExternalConsentActions.success, (state, { consent }) => ({
      ...state,
      consent,
      loading: false,
    })),
    on(ExternalConsentActions.failure, (state, { error }) => ({
        ...state,
        container: { ...state.consents, consents: [], loading: false }
    })),
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
  selectConsentsState, // feature selector
  selectConsents, // selector for `consent` property
  selectLoading, // selector for `loading` property
} = consentsFeature;