import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
  PLATFORM_ID,
  Inject,
  input
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { filter, Observable, Subscription } from 'rxjs';
import { AuthLoginState } from '../../core/auth/auth.models';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'dottnet-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  imports: [TranslateModule]
})
export class FooterComponent implements OnInit, OnDestroy {
  readonly authLoginState$ = input<Observable<AuthLoginState>>(undefined);
  readonly facebookLink$ = input<string>(undefined);
  readonly twitterLink$ = input<string>(undefined);
  readonly linkedinLink$ = input<string>(undefined);
  readonly instagramLink$ = input<string>(undefined);
  isUserLogged: boolean = false;

  // Subs
  userLoggedSub: Subscription;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.userLoggedSub = this.authLoginState$()
        .pipe(filter((authLoginState) => !!authLoginState))
        .subscribe((authLoginState) => {
          this.isUserLogged = [AuthLoginState.LOGGEDHARD, AuthLoginState.LOGGEDSOFT].includes(
            authLoginState
          );
          this.changeDetector.detectChanges();
        });
    }
  }

  ngOnDestroy(): void {
    if (this.userLoggedSub) this.userLoggedSub.unsubscribe();
  }
}
