import { createSession } from '../../core/session/session.actions';
import { onlyWhenOnline, onlyWhenAuthenticated } from '../../shared/util/operators';
import { selectAuthLoginState } from '../../core/auth/auth.selectors';
import {
  setLocalStorageAuth,
  authSoftLogin,
  authGuestLogin,
  authUserLoadedGuest
} from '../../core/auth/auth.actions';
import { activateUser, loadUserGuest } from '../../domain/user/user.actions';
import {
  ParamMap,
  Params,
} from '@angular/router';
import { Token, LoginType, AuthLoginState } from '../../core/auth/auth.models';
import { isPlatformBrowser } from '@angular/common';
import { loadTopic } from '../../domain/topic/topic.actions';
import { loadTemplateCt } from '../../domain/template-ct/template-ct.actions';
import { replaceAll, getQueryParametersFromURL, evaluateBooleanFromString } from '../../shared/util/util';
import { Observable, merge, of, take, catchError, switchMap, concatMap, mergeMap, withLatestFrom } from 'rxjs';
import { LocalStorageService } from '../../core/local-storage/local-storage.service';
import { Store } from '@ngrx/store';
import { LogService } from '../../core/log/log.service';
import { EnvironmentService } from '../../../environments/environment.service';
import { TranslateService } from '@ngx-translate/core';
import { dnLivreaConfig } from './init.actions';
import { ExternalConsent } from 'src/app/domain/external-consent/external-consent.model';
import { selectUser } from 'src/app/domain/user/user.selectors';
import { ExternalConsentActions } from 'src/app/domain/external-consent/external-consent.actions';

export function initDottnet(
  platformId: any,
  store: Store,
  localStorageService: LocalStorageService,
  logService: LogService,
  envService: EnvironmentService,
  translateService: TranslateService
): Observable<any> {
  const currentUrl: URL = new URL(window.location.href);

  logService.init(true, true, false, envService.logLevel);
  logService.info('Inside initDottnet logService');

  // clean dirty chars from url
  let pathName: string = replaceAll(currentUrl.pathname, '(', '%28');
  pathName = replaceAll(pathName, ')', '%29');
  if (pathName !== currentUrl.pathname) {
    currentUrl.pathname = pathName;
    history.pushState({}, '', currentUrl.href);
  }

  store.dispatch(loadTemplateCt());
  store.dispatch(loadTopic());
  store.dispatch(dnLivreaConfig());

  if (isPlatformBrowser(platformId)) {
    logService.info('initDottnet Running in browser environment');
  } else {
    logService.info('initDottnet Running in SSR environment');
  }

  if (isPlatformBrowser(platformId)) {
    return merge(
      of(window.location.href).pipe(
        concatMap((currentUrl: string) => {
          logService.info('initDottnet - merge ');
          translateService.use('it');
          document.documentElement.lang = 'it';

          const urlPath: string = currentUrl.replace(envService.clientDomain, '');
          const params: ParamMap = getQueryParametersFromURL(urlPath);

          let oldSessionID = <number>localStorageService.getItem('SESSIONID');
          if (oldSessionID === null) {
            oldSessionID = 0;
          }

          const renewToken = <Token>localStorageService.getItem('RENEW');
          const currentLoginType = <LoginType>localStorageService.getItem('LOGINTYPE');

          let softToken: string = '';
          let confirmationMailToken: string = '';
          let renewFound: boolean = false;
          let softFound: boolean = false;

          if (params.has('tkcm') && params.get('tkcm').length > 0) {
            let referrer: string = '';
            if (isPlatformBrowser(platformId)) {
              referrer = document.referrer;
            }

            confirmationMailToken = params.get('tkcm');
            store.dispatch(activateUser({
              token: confirmationMailToken, callbackUrl: urlPath, params: params,
              oldSessionID: oldSessionID,
              url: urlPath,
              referrer: referrer
            }));
          } else {
            if (params.has('tksl') && params.get('tksl').length > 0) {
              softToken = params.get('tksl');
              softFound = true;
            }
          }

          if (renewToken !== null && currentLoginType !== null) {
            logService.info('initDottnet - Found renew token in local storage: ', renewToken.token);
            logService.info('initDottnet - Found login type in local storage: ', currentLoginType);

            const scadenzaRenewToken = renewToken.expires;
            const adesso = Math.floor(Date.now() / 1000);
            if (scadenzaRenewToken > adesso && scadenzaRenewToken - adesso > 600) {
              renewFound = true;
            } else {
              logService.info('initDottnet - Removing renew token because expired: ', renewToken.token);
              localStorageService.removeItem('RENEW');
              localStorageService.removeItem('LOGINTYPE');
            }
          }

          if (renewFound && softFound && currentLoginType === LoginType.HARDLOGIN) {
            store.dispatch(setLocalStorageAuth({ token: renewToken, loginType: currentLoginType }));
          }

          if (renewFound && softFound && (currentLoginType === LoginType.SOFTLOGIN || currentLoginType === LoginType.GUESTLOGIN)) {
            store.dispatch(authSoftLogin({ token: softToken }));
          }

          if (renewFound && !softFound) {
            store.dispatch(setLocalStorageAuth({ token: renewToken, loginType: currentLoginType }));
          }

          if (!renewFound && softFound) {
            store.dispatch(authSoftLogin({ token: softToken }));
          }

          if (!renewFound && !softFound) {
            logService.info('initDottnet - Renew token not found in local storage. Doing guest login: ', undefined);
            store.dispatch(authGuestLogin());
          }

          return of(null);
        }),
        catchError((error) => {
          logService.error('initDottnet - Error in first Observable:', error);
          return of(null);
        }),
        take(1)
      ),
      store.select(selectAuthLoginState).pipe(
        onlyWhenOnline(store),
        onlyWhenAuthenticated(store),
        take(1),
        withLatestFrom(store.select(selectUser)),
        mergeMap(([authLoginState, userDTO]) => {
          const url: string = window.location.href.replace(envService.clientDomain, '');
          const params: ParamMap = getQueryParametersFromURL(url);

          let oldSessionID = <number>localStorageService.getItem('SESSIONID');
          if (oldSessionID === null) {
            oldSessionID = 0;
          }

          logService.info('initDottnet - Creating session. current login state: ', authLoginState.toString());

          let referrer: string = '';
          if (isPlatformBrowser(platformId)) {
            referrer = document.referrer;
          }

          store.dispatch(createSession({
            params: params,
            oldSessionID: oldSessionID,
            url: url,
            referrer: referrer
          }));

          if (params.has('tag') && params.get('tag').length > 0 && params.has('num') && params.get('num').length > 0 && params.has('flg1') && params.get('flg1').length > 0) {
            let externalConsentPayload: ExternalConsent = <ExternalConsent>{};

            externalConsentPayload.tagGlobale = parseInt(params.get('tag'), 10);
            externalConsentPayload.numberId = parseInt(params.get('num'), 10);
            externalConsentPayload.idAnagrafica = userDTO.idAnagrafica;
            externalConsentPayload.flag1 = evaluateBooleanFromString(params.get('flg1'));

            if (params.has('flg2') && params.get('flg2').length > 0) {
              externalConsentPayload.flag2 = evaluateBooleanFromString(params.get('flg2'));
            }

            if (params.has('flg3') && params.get('flg3').length > 0) {
              externalConsentPayload.flag3 = evaluateBooleanFromString(params.get('flg3'));
            }

            if (params.has('flg4') && params.get('flg4').length > 0) {
              externalConsentPayload.flag4 = evaluateBooleanFromString(params.get('flg4'));
            }

            store.dispatch(ExternalConsentActions.try({ externalConsent: externalConsentPayload }));
          }


          return of(null);
        }),
        catchError((error) => {
          logService.error('initDottnet - Error in second Observable:', error);
          return of(null);
        })
      )
    );
  } else {
    return of(true).pipe(
      concatMap(() => {
        translateService.use('it');
        logService.info('initDottnet - (AppComponent) Dispatch GuestLogin auth for SSR');
        store.dispatch(authUserLoadedGuest());
        store.dispatch(loadUserGuest({}));
        return of(null);
      })
    );
  }
}
