import { isPlatformBrowser, AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../../environments/environment.service';
import { AuthLoginState } from '../core/auth/auth.models';
import { selectAuthLoginState } from '../core/auth/auth.selectors';
import { LogService } from '../core/log/log.service';
import { UserDTO } from '../domain/user/user.model';
import { selectUser } from '../domain/user/user.selectors';
import { selectCurrentTopic } from '../domain/topic/topic.selector';
import { Topic } from '../domain/topic/topic.model';
import { RouterOutlet } from '@angular/router';
import { MatSidenavModule } from '@angular/material/sidenav';
import { FooterComponent } from '../shared/footer/footer.component';
import { ToolbarComponent } from '../shared/toolbar/toolbar.component';
import { SidenavComponent } from '../shared/sidenav/sidenav.component';
import { ConsentManagementComponent } from '../shared/consent-management/consent-management.component';

@Component({
  selector: 'dottnet-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatSidenavModule, RouterOutlet, AsyncPipe, FooterComponent, ToolbarComponent, SidenavComponent, ConsentManagementComponent]
})
export class AppComponent implements OnInit {
  isProd: boolean;
  envName: string;
  fbLink: string;
  twitterLink: string;
  linkedinLink: string;
  instagramLink: string;
  version: any;

  authLoginState$: Observable<AuthLoginState>;
  user$: Observable<UserDTO>;
  searchText: string;
  topic$: Observable<Topic>;

  isBrowser: boolean = false;

  constructor(
    private store: Store,
    private environmentService: EnvironmentService,
    private logService: LogService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit(): void {
    this.isBrowser = isPlatformBrowser(this.platformId);

    this.isProd = this.environmentService.production;
    this.envName = this.environmentService.envName;
    this.version = this.environmentService.versions.app;
    this.fbLink = this.environmentService.fbLink;
    this.twitterLink = this.environmentService.twitterLink;
    this.linkedinLink = this.environmentService.linkedinLink;
    this.instagramLink = this.environmentService.instagramLink;
    this.authLoginState$ = this.store.select(selectAuthLoginState);
    this.user$ = this.store.select(selectUser);
    this.topic$ = this.store.select(selectCurrentTopic);

    if (!this.isBrowser) {
      this.logService.info('Start rendering app server-side');
    }
  }
}
